import { getUser } from "../composables/getUser";
import security from "./security";

let directives = {
  focus: {
    mounted(el) {
      el.focus();
    },
  },
  roles: {
    mounted(el, binding) {
      const { user } = getUser();
      if (!binding.value.includes(user.value.role)) {
        el.parentNode.removeChild(el);
      }
    },
  },
  role: {
    mounted(el, binding) {
      const { user } = getUser();
      // const roles = [];
      // Object.keys(user.value.roles).map((role) => {
      //   roles.push(role);
      // });
      if (user.value.role !== binding.arg) {
        // if (!user.value.roles.includes(binding.arg)) {
        el.parentNode.removeChild(el);
      }
    },
  },
  board: {
    mounted(el, binding) {
      if (!binding.instance.$route.query.board) {
        el.parentNode.removeChild(el);
      }
    },
  },
};

directives = { ...directives, ...security };

export default function (app) {
  Object.entries(directives).forEach((d) => {
    app.directive(d[0], d[1]);
  });
}
