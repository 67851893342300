const objectSub = (dbRef, { data, error, pending }) => {
  return (snapshot) => {
    if (snapshot.exists()) {
      data.value = snapshot.val();
      error.value = null;
    } else {
      data.value = [];
      error.value = { message: "Aucune entrée" };
    }
    pending.value = false;
  };
};

const arraySub = (dbRef, { data, error, pending }) => {
  return (snapshot) => {
    if (snapshot.exists()) {
      data.value = [];
      Object.entries(snapshot.val()).forEach((doc) => {
        data.value.push({ id: doc[0], ...doc[1] });
      });
      error.value = null;
    } else {
      data.value = [];
      error.value = { message: "Aucune entrée" };
    }
    pending.value = false;
  };
};

export { objectSub, arraySub };
