<template>
  <div class="input-group">
    <label>
      <template v-if="!error">
        <slot></slot>
        <span class="error">{{ required ? " *" : "" }}</span>
      </template>
      <template v-else>
        <span class="error">{{ error }}</span>
      </template>
    </label>
    <input
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      :class="error ? 'error' : ''"
      :required="required"
      :disabled="disabled"
    />
    <fa-icon
      class="icon"
      icon="times-circle"
      v-show="modelValue"
      @click="$emit('update:modelValue', '')"
    ></fa-icon>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    error: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" src="../../assets/_form.scss"></style>
