<template>
  <div class="btn-action-wrapper" :class="disabled ? 'disabled' : ''">
    <span v-if="$slots.default && left"><slot></slot></span>
    <button
      class="btn-action"
      :class="getClasses"
      :disabled="disabled"
      :style="getStyle"
    >
      <fa-icon :icon="icon"></fa-icon>
    </button>
    <span v-if="$slots.default && !left"><slot></slot></span>
  </div>
</template>

<script>
import { computed } from "vue";
import colors from "../../assets/_colors.js";

export default {
  props: {
    disabled: Boolean,
    icon: String,
    size: {
      type: [String, Number],
      default: "2rem",
    },
    color: {
      type: String,
      default: "primary",
    },
    left: {
      type: Boolean,
      default: false,
    },
    revert: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getClasses = computed(() => {
      let classes = [];
      classes.push(props.size);
      return classes;
    });

    const getStyle = computed(() => {
      return {
        height: props.size,
        width: props.size,
        backgroundColor: props.revert ? "transparent" : colors[props.color],
        color: props.revert ? colors[props.color] : "white",
        fontSize: props.revert ? "1.25rem" : "0.75rem",
      };
    });

    return { getClasses, getStyle, colors };
  },
};
</script>

<style lang="scss" scoped>
.btn-action-wrapper {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 0 0.5rem;
  span {
    font-size: 0.75rem;
  }
  &.disabled {
    color: #cccccc;
    pointer-events: none;
    .btn-action {
      background-color: #f0f0f0 !important;
    }
  }
}
.btn-action {
  border: none;
  outline: none;
  border-radius: 10rem;
  background-color: $primary;
  //font-size: 0.85rem;
  cursor: pointer;
  transition: all 300ms ease;
  //margin: 1rem;
}
</style>
