<template>
  <fieldset class="infos p-ripple" v-ripple @click="expand = !expand">
    <template v-if="fold">
      <transition name="fade" mode="out-in">
        <p v-if="expand">{{ content }}</p>
        <p v-else>{{ truncate(content, { length: 24, separator: /,? +/ }) }}</p>
      </transition>
      <!--    <i class="fa" :class="expand ? 'fa-caret-up' : 'fa-caret-down'"></i>-->
      <i
        class="fa fa-caret-up"
        :style="
          expand ? 'transform: rotate(0deg)' : 'transform: rotate(180deg)'
        "
      ></i>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </fieldset>
</template>

<script>
import { ref } from "vue";
import { truncate } from "lodash";

export default {
  props: {
    fold: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "Lorem ipsum dolor sit amet.",
    },
  },
  setup() {
    const expand = ref(true);

    return { expand, truncate };
  },
};
</script>

<style lang="scss">
fieldset {
  position: relative;
  font-size: 0.8rem;
  //min-height: 38px;
  padding: 0.25rem;
  p {
    padding: 0.25rem;
    margin: 0;
  }
  i {
    font-size: 1rem;
    position: absolute;
    right: 0.5rem;
    bottom: 10px;
    //bottom: 50%;
    transition: all 250ms;
  }
}
</style>
