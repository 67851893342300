<template>
  <header class="flex justify-content-between align-items-center">
    <router-link custom v-slot="{ navigate }" :to="{ name: 'Home' }">
      <div class="title mr-auto flex align-items-center" @click="navigate">
        <i :class="icon" class="mr-2 text-primary-dark"></i>
        <span>{{ header }}</span>
      </div>
    </router-link>

    <!--    <Button-->
    <!--      icon="fa fa-wifi"-->
    <!--      class="p-button-rounded p-button-text"-->
    <!--      :class="!isOnline ? 'p-button-danger' : 'p-button-sm'"-->
    <!--    />-->
    <Button
      :label="truncate(user?.name, { length: 16, separator: /,? +/ })"
      icon="fa fa-user"
      @click="toggle"
      class="p-button-secondary p-button-rounded p-button-sm"
    />
    <Menu :model="items" ref="menu" popup />
  </header>
</template>

<script>
import { ref, watch } from "vue";
import { getUser } from "../composables/getUser";
import useLogout from "../composables/useLogout";
import useNetwork from "../utils/useNetwork";
import { truncate } from "lodash";

export default {
  props: {
    icon: String,
    header: String,
  },
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const { isOnline } = useNetwork();

    const menu = ref();
    const toggle = () => {
      menu.value.toggle(event);
    };
    const items = ref([
      {
        label: isOnline.value ? "En ligne" : "Hors ligne",
        icon: "fa fa-wifi",
        // class: () => "text-primary",
        class: isOnline.value ? "text-primary" : "text-danger",
        style: "font-weight: 300",
        items: [
          {
            label: "Mon compte",
            icon: "pi pi-user",
            disabled: true,
            command: () => {
              console.log("profile");
            },
          },
        ],
      },
      { separator: true },
      {
        label: "Se deconnecter",
        icon: "pi pi-fw pi-power-off",
        style: "color: red",
        command: () => logout(),
      },
    ]);

    watch(isOnline, (val) => {
      if (val) {
        items.value[0].label = "Connecté";
      } else {
        items.value[0].label = "Hors ligne";
      }
    });

    return { user, items, toggle, menu, isOnline, truncate };
  },
};
</script>

<style lang="scss" scoped>
header {
  height: 60px;
  background-color: white;
  box-shadow: 0 4px 8px rgba($secondary-light, 0.1);
  padding: 0 1rem;
  .title {
    font-weight: 500;
  }
  .p-chip {
    font-size: 0.9rem;
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: $primary !important;
    font-size: 0.75rem;
  }
}
</style>
